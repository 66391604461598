import React from 'react'
import sessionManager from '../util/sessionManager'
import socketManager from '../util/socketManager'
import NavBar from '../components/NavBar'


export default function Events() {
	return (
    <main>
      <title>Events</title>

			<NavBar />

			<h1>
				Events
			</h1>

			<p>
				......
			</p>

		</main>
	)
}
